import axios from "axios";
import React, { useState, useEffect } from "react";
import "./testimonials.css";

export default function Testimonials() {
  const [loading, setLoading] = useState(true);
  const [testimonialData, setTestimonial] = useState(true);

  var d = document,
    t = "script",
    o = d.createElement(t),
    s = d.getElementsByTagName(t)[0];
  o.src =
    "https://app.realworklabs.com/static/plugin/loader.js?v=" +
    new Date().getTime();
  window.addEventListener(
    "rwlPluginReady",
    function () {
      window.rwlPlugin.init("https://app.realworklabs.com", "xE4qsz_LutZHAWFB");
    },
    false
  );
  s.parentNode.insertBefore(o, s);

  useEffect(() => {
    axios
      .get("https://app.realworklabs.com/plugin/data?key=xE4qsz_LutZHAWFB")
      .then((res) => {
        setTestimonial(res.data.workSites);

        console.log();
        if (res.data.workSites.length >= 1) {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <section class="breakthrough-page-banner-area my-5">
        <div class="container">
          <div class="video-container" style={{ zIndex: "-10" }}>
            <video autoPlay muted loop id="myVideo">
              <source src="./videos/care-plans.mp4" type="video/mp4" />
            </video>
          </div>

          <div class="row">
            <div class="col-12 banner-head-div">
              <h2 class="banner-head-txt">Testimonials</h2>
            </div>
          </div>
        </div>
      </section>

      {/* {loading ? (
        <div className="container text-center" style={{ marginTop: "150px" }}>
          <img src="./images/loading.gif" alt="" />
        </div>
      ) : (
      <div id="rwl-output" style={{ marginTop: "200px" }}></div>
      

      )} */}

      <iframe src="reviews.html" width="100%" height="800px"></iframe>
    </div>
  );
}
