import React from "react";

export default function Terms() {
  return (
    <div>
      <div class="container" style={{ marginTop: "100px" }}>
        <p>&nbsp;</p>
        <div class="pae-inner-content">
          <div className="text-center my-5">
            <h1 className="display-4 mb-4">
              TERMS &amp; CONDITIONS FOR BREAKTHROUGH HANDYMAN SERVICES
            </h1>
          </div>
          <p>
            We are always happy to help our clients and we go to great length to
            attempt to carry this out. If possible, we will try to work in
            same-day appointments to fit emergencies. Emergencies would classify
            as AC, Electrical, plumbing, or any other issue that is either
            hazardous to the occupants or the property structure itself.
          </p>
          <h2 className="text-center my-5">
            <span className="border-yellow-bottom">APPOINTMENTS</span>{" "}
          </h2>
          <p>
            Our standard process is that the office requires scheduled
            appointments with at least 24 hours&#8217; notice. Any nonemergency
            appointments will only be accepted based upon open availability. If
            the same day is requested, there is a $79.00 rush fee attached.
          </p>
          <p>
            Nonemergency warranty jobs will be scheduled at the earliest open
            availability based on our standard scheduling hours of 7 am -7 pm
            Monday-Friday.
          </p>
          <p>
            Our office phone number is 602-938-5415. Our Handyman Supervisor is
            available Monday &#8211; Friday 8 am &#8211; 7 pm and is happy to
            assist in any way possible: scheduling appointments, answering
            questions, handling warranty calls, and any and all handyman service
            needs or questions.
          </p>
          <p>
            Our scheduling system will send a message once an appointment is
            confirmed via your preferred method: email or text, and the
            automated system will keep you updated as time is closer to your
            appointment. If you have any questions or needs, please call the
            office.
          </p>
          <h2 className="text-center my-5">
            <span className="border-yellow-bottom">CANCELATION FEES</span>
          </h2>
          <p>
            There is a $85 cancellation fee for the cancellation of any
            appointment with less than 24 notice or onsite .
          </p>

          <h2 className="text-center my-5">
            <span className="border-yellow-bottom">RATES</span>
          </h2>
          <p>
            Our standard billing rate is $85/hr. for one person and $149/hr. for
            two people during normal business hours. Minimun call is for 1 hour
            of service or call it a trip charge.There is a rate of $69/hr for
            property management companies and other Real Estate Professionals.
            The rate for evenings and weekends is $99/hr . Handyman verify job
            hours when they are on site and All jobs are billed hourly.
          </p>
          <h2 className="text-center my-5">
            <span className="border-yellow-bottom">MATERIALS</span>
          </h2>
          <p>
            Handyman verify matererials when they are on site.We have no markup
            on materials, but we do charge for the time to go to the store for
            materials when needed. Max time of 2 hours per day and one trip per
            day limit. We encourage everyone to have the materials on-site if
            possible.
          </p>
          <h2 className="text-center my-5">
            <span className="border-yellow-bottom">PAYMENTS</span>
          </h2>
          <p>
            Payment is made upon the completion of the job or end of the
            appointment before the handyman leaves, while we are onsite. There
            are cumulative late fees, and collections for ,someone must
            availaible at the end of the appointment to verify a satisfactory
            job and to make payment whether onsite or not.
            <br />
            <br></br>{" "}
            <i>
              <h5>
                We can only accept debit, credit card .We DO NOT accept cash or
                check.
              </h5>
            </i>{" "}
            <br />
            Our handyman cannot/will not accept any forms of payment on behalf
            of Breakthrough Handyman Services.
          </p>
          <p>
            Feel free to tip the handyman if you feel they did extraordinarily
            well.
          </p>
          <p>
            We have late fee $85 if not paid in same day.
            <br /> <br />
            We always attempt to help everyone to the best of our abilities,
            there is no guarantee of fixing the broken item or issue.
            Communication is the key to successful long-term relationships.
            Having long-term happy clients repeatedly using us for their
            handyman needs is our goal. We have based our business on this
            tradition from the beginning and we hope to carry on this tradition
            with you.
          </p>
          <h2 className="text-center my-5">
            <span className="border-yellow-bottom">WARRANTY</span>
          </h2>
          <p>
            Each Handyman is a 1099 Independent Contractor who has agreed to
            work under Breakthrough Handyman Terms and Conditions. They
            guarantee their own competence and have agreed to provide a
            professional handyman job, within their state scope of expertise.
          </p>

          <p>
            Each Handy person warrants their own work. Not Breakthrough Handyman
            Services as a Company. Handy-people warranty their own work but may
            provide another handyman or company to fulfill their warranty
            promise to a client. Materials are covered under the
            manufacturer&#8217;s warranty. The scope of work completed by the
            handyman with the labor and hours involved is covered under each
            handyperson under each agreed-upon job, independent of any other
            handyman, job, and Breakthrough Handyman Services.
          </p>
          <p>
            Handy people agree to provide a scope of work and hours to complete
            the job and get owner&#8217;s permission upon arrival upon seeing
            the job. Upon any change in the agreed-upon terms, the handy person
            MUST provide the issue, show, demonstrate, and provide proof of
            extra work, hours, or material needed prior to commencing work, to
            the
          </p>
          <p>
            Our handyman are called out as the alternative to the licensed
            contractors, and other professionals,We are paid for our time and
            effort, there is no guarantee the problem will not exceed the scope
            of a handyman
          </p>

          <p className="mb-5">
            Owner/Management for Approval. Once provided approval, will provide
            approval to the office for proper billing.
            <br />
            We ask for Permission, Not for Forgiveness.
          </p>
        </div>
      </div>
    </div>
  );
}
