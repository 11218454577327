import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer class="breakthrough-footer" id="footer-sect">
      <div class="footer-wrapper">
        <div class="container">
          <div class="row footer-trusted-row">
            <div class="col-md-3 trusted-img-div">
              <div class="trusted-img-wrapper">
                <img src="./images/1.png" alt="" class="trusted-img" />
              </div>
            </div>
            <div class="col-md-3 trusted-img-div">
              <div class="trusted-img-wrapper">
                <img
                  src="./images/trusted-img-two.png"
                  alt=""
                  class="trusted-img"
                />
              </div>
            </div>
            <div class="col-md-3 trusted-img-div">
              <div class="trusted-img-wrapper">
                <img
                  src="./images/fifty_reviews.png"
                  alt=""
                  class="trusted-img"
                />
              </div>
            </div>
            <div class="col-md-3 trusted-img-div">
              <div class="trusted-img-wrapper">
                <a
                  href="https://www.expertise.com/az/tempe/handyman"
                  target="_blank"
                >
                  <img src="./images/badge.png" alt="" class="trusted-img-2" />
                </a>
              </div>
            </div>
          </div>

          <div class="row footer-second-row">
            <div class="col-lg-4 col-md-6 footer-logo-div">
              <Link class="navbar-brand" to="/" onClick={scrollToTop}>
                <div class="footer-logo-wrapper">
                  <img src="./images/logo.png" alt="logo BreakThrough " />
                </div>
              </Link>
            </div>

            <div class="col-lg-4 col-md-6 footer-addr-div">
              <div class="footer-addr-wrapper">
                <div class="footer-addr-head-div">
                  <h4 class="footer-addr-head footer-small-head">
                    Breakthrough Handyman Services
                  </h4>
                </div>
                <div class="footer-phone-email-div">
                  <li id="block-10" class="widget widget_block">
                    <p class="footer-email-phone-txt">
                      <a href="tel:(602)-938-5415" class="footer-anch">
                        Ph - 602-938-5415
                      </a>
                    </p>

                    <p class="footer-email-phone-txt">
                      <a
                        href="mailto:Info@BreakthroughHandyman.com"
                        class="footer-anch"
                      >
                        Info@BreakthroughHandyman.com
                      </a>
                    </p>
                    <p class="footer-email-phone-txt">
                      <h3
                        style={{
                          color: "white",
                          textDecoration: "underline",
                          letterSpacing: 5,
                        }}
                      >
                        Opening Hours
                      </h3>
                      <br />
                      <p className="footer-timing-text">
                        Monday - Friday : 8am - 7pm
                      </p>
                      <p className="footer-timing-text">
                        Saturday - Sunday : 9am - 5pm
                      </p>
                    </p>
                  </li>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 footer-social-links-div">
              <div class="footer-social-links-wrapper">
                <div class="footer-social-head-div">
                  <h4 class="footer-addr-head footer-small-head">Follow us</h4>
                </div>

                <li id="block-8" class="widget widget_block">
                  <div class="footer-social-links-inner-div">
                    {" "}
                    <a
                      href="
https://www.facebook.com/breakthroughhandyman"
                      class="footer-social-link-anch"
                    >
                      {" "}
                      <img
                        src="./images/facebook-icon.png"
                        alt=""
                        class="footer-icon"
                      />{" "}
                    </a>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-copy-right-div">
          <div class="footer-copy-right-wrapper">
            <div class="container">
              <div class="row">
                <div class="col footer-copyright-text-div">
                  <li id="block-9" class="widget widget_block">
                    <p class="copy-right-text">
                      Breakthrough Handyman Services, LLC © All Rights Reserved
                      <Link
                        onClick={scrollToTop}
                        to="/privacy-policy"
                        class="footer-privacy-link mx-3"
                      >
                        Privacy Policy
                      </Link>
                    </p>
                    <p class="text-center my-5">
                      <Link
                        onClick={scrollToTop}
                        to="/terms-conditions"
                        class="footer-privacy-link"
                      >
                        Terms and Conditions
                      </Link>
                    </p>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
