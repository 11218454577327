import React from "react";
import CustomerTerms from "../CustomerTerms/CustomerTerms";

const Rates = () => {
  return (
    <section class="rates-term-sect">
      <div class="container">
        <div class="row">
          <div class="col-md-6 rates-content-col">
            <div class="rates-content-wrapper">
              <div class="plans-section-head-div mb-3">
                <h3 class="plans-sect-head-text">Rates and Terms</h3>
              </div>
              <div class="additional-info-div w-90 mx-5">
                <div class="store-visits-div mb-4">
                  <h4 class="additional-info-heading text-warning">
                    Store Visits
                  </h4>
                  <li class="additional-info-text text-muted">
                    Add 1 hour for store visits if materials are not onsite.
                    Always confirm with the customer.
                  </li>
                </div>
                <div class="multiple-jobs-div mb-4">
                  <h4 class="additional-info-heading text-warning">
                    Multiple Jobs
                  </h4>
                  <li class="additional-info-text text-muted">
                    Calculate the total time for multiple jobs by adding the
                    individual times together. Apply hourly pricing for total
                    time based on general hourly rates.
                  </li>
                </div>
                <div class="cancellation-fees-div">
                  <h4 class="additional-info-heading text-warning">
                    Cancellation Fees
                  </h4>
                  <ul class="cancellation-fees-ul text-muted">
                    <li class="cancellation-fees-li">
                      Standard Cancellation Fee (within 24 hours): $99 (charged
                      to customer).
                    </li>
                    <li class="cancellation-fees-li">
                      Same-Day/Onsite Cancellation Fee: $125 (charged to the
                      customer if they cancel onsite or less than 24 hours in
                      advance).
                    </li>
                    <li class="cancellation-fees-li">
                      Handyman Late Cancellation Fee (within 24 hours): Handyman
                      will be charged an $99 fee if they cancel within 24 hours,
                      and the customer will receive a $20 credit.
                    </li>
                  </ul>
                </div>

                <div class="discounts-div">
                  <h4 class="additional-info-heading text-warning">
                    Discounts
                  </h4>
                  <li class="additional-info-text text-muted">
                    For scheduling Christmas Light Removal with installation,
                    apply a 10% discount for scheduling both at the same time.
                    For Christmas Light Removal, estimate half the time it took
                    to install.
                  </li>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 d-flex align-items-center">
            <div class="rates-content-wrapper">
              <div class="plans-section-head-div mb-3">
                <h3 class="plans-sect-head-text">General Procedures</h3>
              </div>
              <div class="col-12">
                <CustomerTerms />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rates;
