import Navbar from "./Components/Navbar/navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home/home";
import CarePlans from "./Components/CarePlans/carePlans";
import Services from "./Components/Services/Services";
import Footer from "./Components/Footer/Footer";
import Careers from "./Components/Careers/careers";
import Testimonials from "./Components/Testimonials/testimonials";
import Privacy from "./Components/legal/privacy";
import Terms from "./Components/legal/terms";
import QueryForms from "./Components/legal/queryforms";

import axios from "axios";
import Testimonial from "./Components/Testimonials/Testimonial";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/care-plans" element={<CarePlans />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/careers" element={<Careers />} />
        <Route exact path="/testimonials" element={<Testimonials />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />
        <Route exact path="/terms-conditions" element={<Terms />} />
        <Route exact path="/form" element={<QueryForms />} />
      </Routes>
      <Footer />
      <script
        async
        type="module"
        src="https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js"
      ></script>
      <zapier-interfaces-chatbot-embed
        is-popup="true"
        chatbot-id="clyi737ft0018463a0e3sn3cq"
        height="600px"
        width="400px"
      ></zapier-interfaces-chatbot-embed>
    </div>
  );
}

export default App;
