import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
export default function QueryForms() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const sendEmail = (event, e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2vyazdn",
        "template_fq4e7dm",
        e.target,
        "7kwEyDk3vRNkzWku2"
      )
      .then(
        (result) => {
          if (result.text == "OK") {
            Swal.fire({
              icon: "success",
              title: "Thankyou for choosing us,We will reach you soon",
              showConfirmButton: true,
              // timer: 1500
            });
            reset();
          } else {
            alert("Failed");
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const onSubmit = (data) => {
    axios
      .post("http://localhost:8080/data", data)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <div class="container" style={{ marginTop: "100px" }}>
        <p>&nbsp;</p>

        <div className="bg-light-gray pt-5">
          <div className="book-appointment">
            <h3 class="text-center book-appointment-heading">
              Fill the form to schedule appointment
            </h3>
          </div>
          <div className="py-lg-5 py-md-3 py-sm-2 w-75 mx-auto">
            <form onSubmit={handleSubmit(sendEmail)}>
              <div class="row mb-4">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label" for="form6Example1">
                      First name<span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("firstName", {
                        required: "First name is required",
                      })}
                      type="text"
                      class="form-control"
                    />
                    <div>
                      <p className="ms-1 text-danger">
                        {errors.firstName?.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label" for="form6Example2">
                      Last name<span className="text-danger">*</span>
                    </label>

                    <input
                      {...register("lastName", {
                        required: "Last name is required",
                      })}
                      type="text"
                      id="form6Example2"
                      class="form-control"
                    />
                    <div>
                      <p className="ms-1 text-danger">
                        {errors.lastName?.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-outline mb-4">
                <label class="form-label" for="form6Example4">
                  Address<span className="text-danger">*</span>
                </label>

                <input
                  {...register("address", { required: "Address is required" })}
                  type="text"
                  id="form6Example4"
                  class="form-control"
                />
                <div>
                  <p className="ms-1 text-danger">{errors.address?.message}</p>
                </div>
              </div>
              <div class="form-outline mb-4">
                <label class="form-label" for="form6Example4">
                  City<span className="text-danger">*</span>
                </label>

                <input
                  {...register("city", { required: "Address is required" })}
                  type="text"
                  id="form6Example4"
                  class="form-control"
                />
                <div>
                  <p className="ms-1 text-danger">{errors.city?.message}</p>
                </div>
              </div>

              <div class="form-outline mb-4">
                <label class="form-label" for="form6Example6">
                  Phone<span className="text-danger">*</span>
                </label>
                <input
                  {...register("phone", {
                    required: "Phone Number is Required",
                    minLength: { value: 10, message: "Must be 10 digits only" },
                    maxLength: { value: 10, message: "Must be 10 digits only" },
                  })}
                  type="number"
                  id="form6Example6"
                  class="form-control"
                />
                <div>
                  <p className="ms-1 text-danger">{errors.phone?.message}</p>
                </div>
              </div>

              <div class="form-outline mb-4">
                <label class="form-label" for="form6Example7">
                  Project information we need to work on{" "}
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  {...register("serviceInfo", {
                    required: "Please enter the information",
                  })}
                  class="form-control"
                  id="form6Example7"
                  rows="4"
                ></textarea>

                <div>
                  <p className="text-danger ms-1">
                    {errors.serviceInfo?.message}
                  </p>
                </div>
              </div>

              <div className="text-center">
                <button type="submit" class="btn btn-mustard btn-block mb-4">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
